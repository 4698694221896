<template>
  <div>
    <div class="page-content p-normal">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="search.query" @keyup="searchFundingRequests">
          </div>
        </div>
        <div class="col-12 col-md-6 text-center text-md-end mb-3 mb-md-0">
          <div class="change-table-view">
            <button :class="{active: status === null}" @click="getRequestsByStatus(null)">
              All
            </button>
            <button :class="{active: status === 'paid'}"
                    @click="getRequestsByStatus('paid')">
              Paid {{ paidTotal }}
            </button>
            <button :class="{active: status === 'error'}" @click="getRequestsByStatus('error')">
              Error {{ errorTotal }}
            </button>
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Borrower</th>
            <th scope="col">MLO</th>
            <th scope="col">Lender</th>
            <th scope="col">Broker Wire</th>
            <th scope="col">Status / Score</th>
            <th scope="col">Submitted On</th>
            <th scope="col">Due to Salesperson</th>
            <th scope="col">Actions</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fr, index) in pendingRequests" :key="index">
            <td class="cursor-pointer table-borrower-name" @click="toggleModal('fundingDetails', fr.id)">
              {{ fr.borrowerName }}
            </td>
            <td>
              <div class="agents-avatars" v-if="isGranted('ROLE_BOOKKEEPER')">
                <a v-for="a in fr.agents" :key="fr.id + '_' + a.id" :href="backendUrl('/mlo/account/agent/' + a.id)" target="_blank">
                  <img :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
                </a>
              </div>
              <div class="agents-avatars" v-else>
                <img v-for="a in fr.agents" :key="fr.id + '_' + a.id" :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
              </div>
            </td>
            <td>{{ fr.lender }}</td>
            <td>
              <span v-tippy :content="capitalize(fr.type)">
                <span v-if="fr.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
                  <b>{{ convertToCurrency(fr.wireAmount) }}</b>
                </span>
                <span v-else><b>N/A</b></span>
                ({{ formatDate(fr.wireDate) }})
              </span>
            </td>
            <td>
              <template v-if="isGranted(ROLE_BOOKKEEPER)">
                <div class="btn-group">
                  <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'waiting_for_payment' || fr.status === 'paid',
                  }" @click="toggleModal('scoreModal', fr.id)">
                    {{ fr.statusForHumans }}
                  </div>
                  <div class="btn btn-br-default" :class="{
                    'btn-danger': fr.score < 40,
                    'btn-primary': fr.score >= 40 && fr.score < 70,
                    'btn-success': fr.score >= 70,
                  }" @click="toggleModal('scoreModal', fr.id)">
                    <span>
                      0
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'paid' || fr.status === 'waiting_for_payment',
                  }">
                  {{ fr.statusForHumans }}
                </div>
              </template>
            </td>
            <td>{{ formatDate(fr.dateOfSubmission, 'MMM D, Y') }}</td>
            <td>
              <b>{{ convertToCurrency(fr.dueToSalesperson) }}</b>
            </td>
            <td class="actions-buttons">
              <button class="btn btn-primary" @click="toggleModal('fundingDetails', fr.id)">
                Details
              </button>
              <button v-if="fr.isGranted.edit" class="btn btn-secondary" @click="toggleModal('editFunding', fr.id)">
                Edit
              </button>
              <button v-if="fr.isGranted.pay" class="btn btn-success" @click="pay(fr)">
                <span v-if="ui.pay.loading.includes(fr.id)" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
                <span v-else>Pay</span>
              </button>
              <button v-if="fr.isGranted.rollback" class="btn btn-outline-primary" @click="rollback(fr)">
                <span v-if="ui.rollback.loading.includes(fr.id)" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
                <span v-else>Rollback</span>
              </button>
            </td>
            <td>
              <div v-if="fr.isGranted.delete">
                <button class="btn btn-control ms-2" :name="'fr_action' + index">
                  <img src="@/assets/icons/icon-more.svg" alt="">
                </button>
                <tippy boundary="window"
                       interactive="true"
                       trigger="click"
                       class="borrower-action"
                       zIndex="1039"
                       animateFill="false"
                       placement="bottom-end"
                       theme="light"
                       :to="'fr_action' + index">
                  <div class="table-menu-block">
                    <ul>
                      <li class="action-label">Actions</li>
                      <li v-if="fr.isGranted.delete" @click="deleteFunding(fr.id)" class="cursor-pointer">
                        <img src="@/assets/icons/icon-delete.svg" alt="">
                        Delete
                      </li>
                    </ul>
                  </div>
                </tippy>
              </div>
            </td>
          </tr>
          <tr v-if="pendingRequests.length === 0">
            <td colspan="8" class="text-center">
              <div class="m-5">
                <strong class="no-result">No borrower yet.</strong><br>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Paging :paging="paging" @pageChanged="pagingUpdated" @limitChanged="pagingUpdated"></Paging>
    </div>

    <transition name="component-fade" mode="out-in">
      <export-modal
        v-if="modals.exportModal === true"
        :selected-ids="getSelectedIds()"
        @close="modals.exportModal = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <funding-details-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.fundingDetails === true"
        @close="toggleModal"
        @editFunding="modals.editFunding = true"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <score-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.scoreModal === true"
        @close="toggleModal"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <edit-funding-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.editFunding === true"
        @close="toggleModal"/>
    </transition>
  </div>
</template>

<script>
import ExportModal from "./modals/ExportModal";
import FundingDetailsModal from "./modals/FundingDetailsModal";
import EditFundingModal from "./modals/EditFundingModal";
import ScoreModal from "./modals/ScoreModal";
import Paging from "../../components/Paging";

export default {
  name: "FundingRequestsProcessed",
  components: {Paging, ExportModal, FundingDetailsModal, EditFundingModal, ScoreModal},
  data() {
    return {
      showSelect: false,
      showModalNewFunding: false,
      showModalEditFunding: false,
      pendingRequests: [],
      processedRequests: [],
      deletedRequests: [],
      transactions: [],
      selectAllCheckbox: false,
      selectedFundingRequestId: null,
      selectedCount: 0,
      errorTotal: 0,
      paidTotal: 0,
      status: null,
      ui: {
        loading: false,
        pay: {loading: []},
        rollback: {loading: []},
      },
      modals: {
        exportModal: false,
        fundingDetails: false,
        editFunding: false,
        customFeeModal: false,
        scoreModal: false,
      },
      payment: {
        customFee: false
      },
      search: {
        timer: null,
        query: null
      },
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      }
    }
  },
  methods: {
    getPendingRequests() {
      this.ui.loading = true;
      let params = {
        offset: this.paging.offset,
        limit: this.paging.limit,
        query: this.search.query,
        status: this.status ? this.status : null,
      };
      this.$http.get('/api/v1/funding_requests/processed', {params: params})
        .then((res) => {
          this.ui.loading = false;
          this.pendingRequests = res.data.processedRequests
          this.paging = res.data.paging
          if (!this.status) {
            this.errorTotal = res.data.errorTotal
            this.paidTotal = res.data.paidTotal
          }

          this.pendingRequests.map((request) => {
            request.checked = false;
          })
        })
        .catch()
    },
    loadScore(id) {
      this.$http.get(`/api/v1/funding_requests/get-score/${id}`)
        .then((res) => {
          this.pendingRequests.map((request) => {
            if (request.id === id) {
              request.score = res.data.score;
            }
          })
        })
        .catch()
    },
    showSelectNav() {
      this.showSelect = !this.showSelect;
    },
    getRequestsByStatus(status) {
      this.status = status;
      this.paging.offset = 0;
      this.getPendingRequests()
    },
    selectAll(val) {
      if (val === false) {
        this.pendingRequests.map((request) => {
          request.checked = true;
          this.selectedCount++;
        })
      } else {
        this.pendingRequests.map((request) => {
          request.checked = false;
          this.selectedCount--;
        })
      }
    },
    deleteFunding(id, deleteSelected = false) {
      if (confirm('Are you sure?')) {
        this.$http
          .delete(`/api/v1/funding-requests/delete/${id}`)
          .then(() => {
            if (deleteSelected && this.getSelectedIds().length > 0) {
              this.toggleCheckboxItem(id)
              this.deleteFunding(this.getSelectedIds()[0], true)
            }

            this.getPendingRequests()
          })
      }
    },
    toggleModal(modalName, fundingId = null, updateTableData = false) {
      this.modals[modalName] = !this.modals[modalName]
      this.selectedFundingRequestId = fundingId
      if (updateTableData) {
        this.getPendingRequests()
      }
    },
    pay(fr) {
      this.ui.pay.loading.push(fr.id);
      this.$http
        .post(`/api/v1/funding_requests/pay/${fr.id}`)
        .then(() => {
          this.pendingRequests = this.pendingRequests.filter((f) => f.id !== fr.id);
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `${fr.borrowerName} has been successfully paid`,
            status: 'success',
            delay: 2000
          });
        })
        .catch((e) => {
          let err = this.getFirstErrorFromResponse(e.response)
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `Error! ${err}`,
            status: 'error',
            delay: 3000
          });
        }).finally(() => {
        this.ui.pay.loading = this.ui.pay.loading.filter(id => id !== fr.id)
      })
    },
    rollback(fr) {
      this.ui.rollback.loading.push(fr.id);
      this.$http
        .post(`/api/v1/funding-requests/rollback/${fr.id}`)
        .then(() => {
          this.pendingRequests = this.pendingRequests.filter((f) => f.id !== fr.id);
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `${fr.borrowerName} has been successfully rolled back`,
            status: 'success',
            delay: 2000
          });
        })
        .catch((e) => {
          let err = this.getFirstErrorFromResponse(e.response)
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `Error! ${err}`,
            status: 'error',
            delay: 5000
          });
        }).finally(() => {
        this.ui.rollback.loading = this.ui.rollback.loading.filter(id => id !== fr.id)
      })
    },
    openPaymentCustomModal(id) {
      this.selectedFundingRequestId = id;
      this.modals.payment = true;
      this.payment.customFee = true;
    },
    toggleCheckboxItem(id) {
      this.selectedCount = 0
      this.pendingRequests.map(item => {
        if (item.id === id) {
          item.checked = !item.checked
        }

        if (item.checked)
          this.selectedCount++
      })

      let checkbox = document.getElementById('checkbox-select')
      if (this.selectedCount > 0 && this.selectedCount < this.pendingRequests.length) {
        checkbox.indeterminate = true
      } else if (this.selectedCount === this.pendingRequests.length) {
        checkbox.indeterminate = false
        checkbox.checked = true
      } else {
        checkbox.indeterminate = false
        checkbox.checked = false
      }
    },
    searchFundingRequests() {
      if (this.search.timer) {
        clearTimeout(this.search.timer)
      }

      this.search.timer = setTimeout(() => {
        this.getPendingRequests()
      }, 800)
    },
    getSelectedIds() {
      let ids = [];

      this.pendingRequests.map(item => {
        if (item.checked) {
          ids.push(item.id)
        }
      })

      return ids
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.getPendingRequests();
    },
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    editAllow() {
      return this.selectedCount !== 1
    },
    deleteAllow() {
      return this.selectedCount === 0
    }
  },
  beforeMount() {
    this.getPendingRequests()
    if (this.$route.query.fr) {
      this.toggleModal('fundingDetails', Number.parseInt(this.$route.query.fr));
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      vertical-align: middle;

      td {
        color: rgba(0, 0, 0, .8);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;

        &:not(:first-child) {
          padding-left: 0;
        }
      }

      .btn {
        max-height: 32px;
        height: 32px;
        font-size: 12px;
        line-height: 1.5;
      }

      .agents-avatars {
        img {
          height: 34px;
          width: 34px;
          border: 2px solid #F6F6F6;
          border-radius: 50%;

          &:nth-child(n + 2) {
            margin-left: -5px;
          }
        }
      }
    }
  }

  .actions-buttons {
    .btn {
      margin-right: 0.25rem;
    }
  }
}

.modal {
  .tab-list {
    padding: 0;

    li {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-check-input {
  border-width: 2px;
}
</style>
